'use strict';

angular.module('cpformplastApp.inventory')
    .controller('locationsController', function($scope, DataManager, $state, Notification) {

  $scope.locations = [];
  $scope.contents = [
    { value: 'Les deux', rawValue: 'all'},
    { value: 'Matière première', rawValue: 'raw-material'},
    { value: 'Produit fini', rawValue: 'finished-product'}
  ];

  $scope.locationTableButtons = [{
    'icon':'plus',
    'title':'Ajouter',
    'function': function() {
      $state.go('inventory/locations/location-creation');
    }
  }];

  $scope.locationTableStructure = [{
    'title':"Emplacements dans l'entrepôt",
    'value':'name',
    'filter':'alpha'
  },{
    'title':"Contenu",
    'value':'content',
    'filter':'alpha'
  }];

  $scope.init = function() {
    DataManager.fetchLocations().then((data) => {
      $scope.locations = data;
      for (var i=0 ; i<$scope.locations.length ; i++) {
        $scope.locations[i].name = $scope.locations[i].zone + $scope.locations[i].number;
        if ($scope.locations[i].level > 1) {
          $scope.locations[i].name += '-' + $scope.locations[i].level;
        }
        $scope.locations[i].content = $scope.contents.find(content => content.rawValue === $scope.locations[i].content).value;
      }
    })
    .catch(err => {
      console.log(err);
      Notification.error('Un probème est survenu lors du chargement des jobs');
    });
  };

  $scope.selectLocation = function(location) {
    $state.go('inventory/locations/location-creation', {'locationId': location._id});
  };

  $scope.init();

});
